import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import portable_compactor from "../../public/static/images/products/portable_compactor.jpg"

function PortableCompactor() {

  const prodDetail = {
    "name": "Portable Compactor",
    "detail": {
      "about" : ["Portable Compactors are supplied in roll-on/roll-off (hook lift) formats, to suit customer’s transport requirements and with integrated or external bin lifts. With compaction ratios of around 5:1 a portable compactor can be hold much more waste than a standard skip, meaning many fewer collections for the waste to be disposed."],
      "advantages": [
        "Smaller businesses who possibly have limited space on their premises, this means they require less room and are easy to move if required.",
        "As they’re smaller than static waste compactors, they fill up quicker, yet are the perfect choice if you’re producing smaller amounts of waste.",
        "One of the main benefits of a portable waste compactor is that it is fully enclosed, meaning waste is completely contained."
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Chassis",
        "Booms",
        "Arrangement"
      ],
      "column2": [
        "IEPC",
        "12-25 Ton or Customized",
        "Heavy Duty Booms of CRC/Eq. Sheets",
        "Hydraulic Cylinder of High Load Capacity, Hydraulic Pump & Others Efficient Controlling Systems to Easy Lifting of Big Size Containers From One Place to Other."
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={portable_compactor} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default PortableCompactor
